.auth_wrapper {
    margin-top: 150px;
    text-align: center;
  }
  
  .logo {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    height: 27px;
    width: 270px;
  }

.auth_bottom_text, .link_adornment, .pre_form_text {
  font-size: small;
}

.pre_form_text {
  padding: 10px;
}

.link_adornment, .auth_bottom_text a {
  color: #42a5f5;
  text-decoration: none;
  cursor: pointer;
}
