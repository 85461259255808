.userInfoWrapper {
    margin-top: 100px;
  }
  
  .listItem  {
    padding-right: 0 !important;
  }

  .listItemHidden {
    display: none;
  }
  
  .listItemButton {
    background-color: #262E3F !important;
    color: #fff !important;
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
  }

  .childrenWrapper {
    margin-left: 30px;
    border-bottom-left-radius: 4px !important;
  }
  
  